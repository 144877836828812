import { Link } from 'gatsby'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

function Eduction() {
    return (
        <>
            <div className="Eduction-section scrollspy-example" id='eductionSectionId' data-bs-spy="scroll" data-bs-target="#right-menu" data-bs-offset="0" tabIndex="0">
                <div className="Eduction" id="Education">
                    <Container>
                        <Row className='align-items-center  justify-content-between' >
                            <Col lg={6} md={12} className="order-lg-1 order-2 " data-aos="zoom-in-right" >
                                <div className="Eduction-img   hide-animation-768 ">
                                    <img src={"../assets/img/industries/education/education.svg"} className="img-fluid wh-auto" alt="education" width="650" height="399"/>
                                    <img src={"../assets/img/industries/education/1.svg"} className="img-fluid Eductionone" alt="education-one" />
                                    <img src={"../assets/img/industries/education/2.svg"} className="img-fluid Eductiontwo" alt="education-two" />
                                </div>

                                <div className="main-img show-main-img-768 ">
                                    <img src="../assets/img/industries/education/education_mobile.svg" className="img-fluid main-img wh-auto" alt="education_mobile" width="680" height="417" />
                                </div>
                            </Col>
                            <Col lg={6} md={12} className="order-lg-1 order-2">
                                <div className="Eduction-content" data-aos="zoom-in-left" >
                                    <h2 >Education</h2>
                                    <p className='education-def-color'>The education industry requires a lot of automation and utilizes technology to its best advantage. We have built a multitude of applications and full-fledged software solutions for our customers in the education sector; ranging from web portals to mobile applications and more.
                                    </p>
                                    {/* <Link to='/portfolio'
                                        state={{ industryTitle: 'Eduction' }}
                                        className='Eduction-btn' style={{ textDecoration: "none" }}>
                                        View Portfolio
                                    </Link> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="Logistic" id='Logistic'>
                    <Container>
                        <Row className='align-items-center  justify-content-between'>

                            <Col lg={6} md={12} className="order-lg-1 order-2 ">
                                <div className="Eduction-content" data-aos="zoom-in-right">
                                    <h2>Logistic</h2>
                                    <p className='logistic-def-color'>The entire logistics and transportation industry runs on organized supply chain management. This can be achieved through simple software integrated with complex operations that aim to reduce time, efforts, and chances of manual errors. Vasundhara Infotech has developed a variety of solutions for the industry. </p>
                                    {/* <Link to='/portfolio'
                                        state={{ industryTitle: 'Logistic' }}
                                        className='Eduction-btn' style={{ textDecoration: "none" }}>
                                        View Protfolio
                                    </Link> */}
                                </div>
                            </Col>

                            <Col lg={6} md={12} className="order-lg-2 order-1    " data-aos="zoom-in-left">
                                <div className="Eduction-img  hide-animation-768">
                                    <img src={"../assets/img/industries/logistic/logistic.svg"} className="img-fluid wh-auto" alt="logistic" width="650" height="423"/>
                                    <img src={"../assets/img/industries/logistic/1.svg"} className="img-fluid Logisticone" alt="logistic-one" />
                                    <img src={"../assets/img/industries/logistic/2.svg"} className="img-fluid Logistictwo" alt="logistic-two" />
                                    <img src={"../assets/img/industries/logistic/3.svg"} className="img-fluid  Logisticthree" alt="logistic-three" />
                                </div>

                                <div className="main-img show-main-img-768 ">
                                    <img src="../assets/img/industries/logistic/logistic_mobile.svg" className="img-fluid main-img wh-auto" alt="logistic_mobile" width="680" height="443" />
                                </div>
                            </Col>

                        </Row>
                    </Container>
                </div>

                <div className="Game" id='Game'>
                    <Container>
                        <Row className='align-items-center justify-content-between' >
                            <Col lg={6} md={12} className="order-lg-2 order-1 " data-aos="zoom-in-right"  >
                                <div className="Eduction-img img-show  ">
                                    <img src={"../assets/img/industries/game/game.svg"} className="wh-auto img-fluid" alt="game" width="650" height="377"/>
                                    <img src={"../assets/img/industries/game/p.svg"} className="img-fluid p" alt="game-p" />
                                    <img src={"../assets/img/industries/game/l.svg"} className="img-fluid L" alt="game-l" />
                                    <img src={"../assets/img/industries/game/a.svg"} className="img-fluid A" alt="game-a" />
                                    <img src={"../assets/img/industries/game/y.svg"} className="img-fluid Y" alt="game-y" />
                                    <img src={"../assets/img/industries/game/1.svg"} className="img-fluid dotsone" alt="game-1" />
                                    <img src={"../assets/img/industries/game/1.svg"} className="img-fluid dotstwo" alt="game-1" />
                                    <img src={"../assets/img/industries/game/1.svg"} className="img-fluid dotsthree" alt="game-1" />
                                    <img src={"../assets/img/industries/game/2.svg"} className="img-fluid lineone" alt="game-2" />

                                </div>

                                <div className="hero-img img-hide text-center" >
                                    <img src={"../assets/img/industries/game/game_mobile.svg"} className="img-fluid  main-img wh-auto" alt="game_mobile" width="680" height="445"/>
                                </div>

                            </Col>

                            <Col lg={6} md={12} className="order-lg-2 order-1   ">
                                <div className="Eduction-content " data-aos="zoom-in-left">
                                    <h2>Gaming</h2>
                                    <p className='game-def-color'>We are enabling the gaming industry to take the next leap of transformation with the help of latest technologies, simulations, characters, and graphics that engage with the end-users in a unique and memorable manner. This has made us the first choice for game development services across the globe. </p>
                                    {/* <Link to='/portfolio'
                                        state={{ industryTitle: 'Game' }}
                                        className='Eduction-btn' style={{ textDecoration: "none" }}>
                                        View Protfolio
                                    </Link> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>


                <div className="e-commerce" id='Ecommerce'>
                    <Container>
                        <Row className='align-items-center  justify-content-between'>
                            <Col lg={6} md={12} className="order-lg-1 order-2 ">
                                <div className="Eduction-content " data-aos="zoom-in-right">
                                    <h2>E-Commerce</h2>
                                    <p className='e-commerce-def-color'>We offer a full range of web and mobile app development services for the E-Commerce industry to help them fulfill their business goals and drive their business forward. In addition, Our services have assisted numerous E-Commerce and retail businesses take their store online and expand their reach.  </p>
                                    {/* <Link to='/portfolio'
                                        state={{ industryTitle: 'Ecommerce' }}
                                        className='Eduction-btn' style={{ textDecoration: "none" }}>
                                        View Protfolio
                                    </Link> */}
                                </div>
                            </Col>

                            <Col lg={6} md={12} className="order-lg-2 order-1 " data-aos="zoom-in-left">
                                <div className="Eduction-img  hide-animation-768  ">
                                    <img src={"../assets/img/industries/e-commerce/ecommerce.svg"} className="wh-auto img-fluid" alt="ecommerce" width="650" height="416"/>
                                    {/* <img src={E_one} className="img-fluid e_one" alt="ecommerce-one" /> */}
                                    <img src={"../assets/img/industries/e-commerce/2.svg"} className="img-fluid e_two-img" alt="ecommerce-two" />
                                    <img src={"../assets/img/industries/e-commerce/3.svg"} className="img-fluid e_three-img" alt="ecommerce-three" />
                                    <img src={"../assets/img/industries/e-commerce/4.svg"} className="img-fluid e_four-img" alt="ecommerce-four" />
                                    <img src={"../assets/img/industries/e-commerce/5.svg"} className="img-fluid e_five-img" alt="ecommerce-five" />
                                </div>
                                <div className="main-img show-main-img-768 ">
                                    <img src="../assets/img/industries/e-commerce/ecommerce_mobile.svg" className="img-fluid main-img wh-auto" alt="ecommerce_mobile" width="680" height="435" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="marketplace pb-100" id='Marketplace'>
                    <Container>
                        <Row className='align-items-center justify-content-between'>
                            <Col lg={6} md={12} data-aos="zoom-in-right">
                                <div className="Eduction-img  hide-animation-768 ">
                                    <img src={"../assets/img/industries/marketplace/marketplace.svg"} className="img-fluid wh-auto" alt="marketplace" width="649" height="371"/>
                                    <img src={"../assets/img/industries/marketplace/1.svg"} className="img-fluid marketplaceone-img" alt="marketplace-one" />
                                    <img src={"../assets/img/industries/marketplace/2.svg"} className="img-fluid marketplacetwo-img" alt="marketplace-two" />
                                    <img src={"../assets/img/industries/marketplace/3.svg"} className="img-fluid marketplacethree-img" alt="marketplace-three" />
                                    <img src={"../assets/img/industries/marketplace/3.svg"} className="img-fluid marketplacefour-img" alt="marketplace-three" />
                                    <img src={"../assets/img/industries/marketplace/4.svg"} className="img-fluid marketplacefive-img" alt="marketplace-four" />

                                </div>
                                <div className="main-img show-main-img-768 ">
                                    <img src="../assets/img/industries/marketplace/marketplace_mobile.svg" className="img-fluid main-img wh-auto" alt="marketplace_mobile" width="680" height="389"/>
                                </div>
                            </Col>

                            <Col lg={6} md={12} >
                                <div className="Eduction-content " data-aos="zoom-in-left">
                                    <h2>Marketplace</h2>
                                    <p className='market-def-color'>Marketplaces bring a considerable amount of data and information on a single platform, which accelerate the growth of a number of businesses simultaneously. The team at Vasundhara is proficient in building comprehensive and dynamic marketplace portals regardless of the industry and background.</p>
                                    {/* <Link to='/portfolio'
                                        state={{ industryTitle: 'Marketplace' }}
                                        className='Eduction-btn' style={{ textDecoration: "none" }}>
                                        View Protfolio
                                    </Link> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default Eduction