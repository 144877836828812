import React, { useEffect } from 'react'
import Eduction from '../components/industries/Eduction';
import Rightmenu from '../components/industries/Rightmenu';
import Layout from '../components/Layout';
import Hero from '../components/Mobileapp/hero';
import Seo from '../components/Seo';
import allApiData from "../api";
import SeoheadData from '../siteScriptData';


function Industries() {

    useEffect(() => {
        let mainNavLinks = document.querySelectorAll(".img-div a");

        typeof window !== "undefined" && window.addEventListener("scroll", event => {
            let fromTop = typeof window !== "undefined" ? window.scrollY - 400 : null;

            mainNavLinks.forEach(link => {
                let section = document.querySelector(link.hash);

                if (section &&
                    section.offsetTop <= fromTop &&
                    section.offsetTop + section.offsetHeight > fromTop
                ) {
                    link.parentElement.classList.add("is-current");
                } else {
                    link.parentElement.classList.remove("is-current");
                }
            });
        });
    }, [])

    useEffect(() => {
        allApiData.aosinit()
    }, []);

    return (
        <Layout>
            <Seo SeoData={SeoheadData.industriesPageSeoData}></Seo>
            <div className='industries'>
                <Hero title="Empowering Industries with Top-notch Software Solutions"
                    cta="Get a Quote"
                    def="Being one of the leading IT solution providers in the India, Our services have helped various firms from vivid industries with sustainable growth over the years. Learn more about the industries we serve.">
                    <div className="hero-img hide-animation-768">
                        <img src="../assets/img/industries/hero_img/industries-we-serve-for-software-development.svg" className="wh-auto img-fluid height-650" alt="industries-we-serve-for-software-development"  width="649" height="650"/>
                        <img src="../assets/img/industries/hero_img/2.svg" className="img-fluid image-three" alt="industries-we-serve-for-software-development-two" />
                        <img src="../assets/img/industries/hero_img/1.svg" className="img-fluid image-five" alt="industries-we-serve-for-software-development-one" />
                    </div>
                    <div className="show-main-img-768">
                        <img src="../assets/img/industries/hero_img/industries-we-serve-for-software-development-mobile.svg" className="img-fluid wh-auto" alt="industries-we-serve-for-software-development-mobile" width="649" height="650" />
                    </div>
                </Hero>
            </div>
            <Eduction />
            <Rightmenu />
        </Layout>
    )
}

export default Industries