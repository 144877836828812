import React from 'react'
import { NavLink } from 'react-bootstrap'
function Rightmenu() {

    return (
        <div className="right-menu" id='right-menu'>
            <div className='img-div'>
                <NavLink href="#Education">
                    <img src={"../assets/img/industries/right_link/education.svg"} className="display-none" alt="education" />
                    <img src={"../assets/img/industries/right_link/1.svg "} className="display-block" alt="education-one" />
                </NavLink>
            </div>
            <div className='img-div'>
                <NavLink href=" #Logistic"> <img src={"../assets/img/industries/right_link/logistic.svg"} className="display-none" alt="logistic" />
                    <img src={"../assets/img/industries/right_link/3.svg "} className="display-block" alt="logistic-three" />
                </NavLink>
            </div>
            <div className='img-div'>
                <NavLink href="#Game"> <img src={"../assets/img/industries/right_link/Layer-2.png"} className="display-none" alt="Layer-2" />
                    <img src={"../assets/img/industries/right_link/2.svg "} className="display-block" alt="Layer-2-two" />
                </NavLink>
            </div>
            <div className='img-div'>
                <NavLink href="#Ecommerce"> <img src={"../assets/img/industries/right_link/ecommerce.svg"} className="display-none" alt="ecommerce" />
                    <img src={"../assets/img/industries/right_link/4.svg "} className="display-block" alt="ecommerce-four" />
                </NavLink>
            </div>
            <div className='img-div'>
                <NavLink href="#Marketplace"> <img src={"../assets/img/industries/right_link/marketplace.svg"} className="display-none" alt="marketplace" />
                    <img src={"../assets/img/industries/right_link/5.svg "} className="display-block" alt="marketplace-five" />
                </NavLink>
            </div>
        </div>
    )
}
export default Rightmenu